import React, {useContext} from 'react';
import ContentSizeLimiter from "../../../../components/UI/Containers/ContentSizeLimiter";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import DefaultButton from "../../../../components/Atomic/Buttons/DefaultButton";

import {
  ContentContainer,
  ContentItemsWrapper,
  SectionContainer,
  ImageContainer,
  TitleText,
  ParagraphItemsWrapper,
  ParagraphText,
  ButtonsWrapper
} from "./styles";
import {CONTACT_US_ROUTE} from "../../../../library/Navigation/NavigationConstants";

const HeroSection = () => {
  const {translations} = useContext(LocalisationContext);
  const translationsObject = translations.staticScreens.technologyScreen.hero;

  return (
    <SectionContainer>
      <ContentSizeLimiter>
        <ContentItemsWrapper>
          <ContentContainer>
            <TitleText>{translationsObject.title}</TitleText>
            <ParagraphItemsWrapper>
              {translationsObject.paragraphs.map((paragraph, index) => (
                <ParagraphText key={index}>{paragraph}</ParagraphText>
              ))}
            </ParagraphItemsWrapper>

            <ButtonsWrapper>
              <DefaultButton to={''}>{translationsObject.moreInfoButtonText}</DefaultButton>
              <DefaultButton color={'#2051E2'} textColor={'white'} to={CONTACT_US_ROUTE}>
                {translationsObject.contactButtonText}
              </DefaultButton>
            </ButtonsWrapper>
          </ContentContainer>
          <ImageContainer />
        </ContentItemsWrapper>
      </ContentSizeLimiter>
    </SectionContainer>
  );
};

export default HeroSection;
