import styled from "styled-components";
import {SectionIcon} from "../../styles";

export const SectionContainer = styled.div`
	display: grid;
	grid-row-gap: 70px;

  ${SectionIcon} {
    background-color: #416FF4;
  }
`;

export const PartnerItemsWrapper = styled.div`
  display: grid;
	grid-template-columns: repeat(2, min(475px, 50%));
	grid-row-gap: 80px;
	grid-column-gap: 80px;
	justify-content: center;
	
	@media all and ${({theme: {device}}) => device.md} {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
  }

  @media all and ${({theme: {device}}) => device.sm} {
    grid-template-columns: min(475px, 100%);
  }
`;

export const PartnerContainer = styled.div`
  display: grid;
  grid-row-gap: 40px;
  padding: 45px;

  border-radius: 5px;
  box-shadow: 0 0 10px 1px rgba(204, 204, 204, 0.5);

  &:hover {
    box-shadow: 0 0 10px 1px #ccc;
  }
`;

export const PartnerLogoContainer = styled.div`
  padding-bottom: 20px;
	border-bottom: 1px solid #EAECED;
`;


export const PartnerDescription = styled.div`
  font-size: 16px;
  line-height: 1.4;
  color: #81838C;
`;



