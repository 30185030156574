import {APP_REGION_CY, APP_REGION_AE, APP_REGION_COM, DEVELOPMENT, STAGING, PRODUCTION} from "../EnvironmentConstants";

const ENV_FILES_BY_REGION = {
  [APP_REGION_CY]: {
    common: require('./../envs/CY/common.json'),
    [DEVELOPMENT]: require('./../envs/CY/development.json'),
    [STAGING]: require('./../envs/CY/staging.json'),
    [PRODUCTION]: require('./../envs/CY/production.json'),
  },
  [APP_REGION_AE]: {
    common: require('./../envs/AE/common.json'),
    [DEVELOPMENT]: require('./../envs/AE/development.json'),
    [STAGING]: require('./../envs/AE/staging.json'),
    [PRODUCTION]: require('./../envs/AE/production.json'),
  },
  [APP_REGION_COM]: {
    common: require('./../envs/COM/common.json'),
    [DEVELOPMENT]: require('./../envs/COM/development.json'),
    [STAGING]: require('./../envs/COM/staging.json'),
    [PRODUCTION]: require('./../envs/COM/production.json'),
  }
};

export default function getRegionEnvironmentVariables(envType, appRegion) {
  if(ENV_FILES_BY_REGION.hasOwnProperty(appRegion) && ENV_FILES_BY_REGION[appRegion].hasOwnProperty(envType)){
    return Object.assign(
      {
        APP_REGION: appRegion,
      },
      ENV_FILES_BY_REGION[appRegion].common,
      ENV_FILES_BY_REGION[appRegion][envType],
    );
  }

  return {
    APP_REGION: appRegion
  }
}
