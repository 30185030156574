import styled from "styled-components";

export const TextWrapper = styled.div`
  display: grid;
	row-gap: 15px;
`;

export const CookieTitleText = styled.div`
  font-size: 16px;
	font-weight: bold;
	color: black;
`;
