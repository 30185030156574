import React from 'react';
import styled from "styled-components";

import Breadcrumbs from "../../../components/Atomic/Breadcrumbs";

 const ComponentContainer = styled.div`
	display: grid;
	grid-row-gap: 50px;
`;

const ScreenHeaderWithBreadcrumbs = ({children, breadcrumbs}) => {
  return (
    <ComponentContainer>
	    <Breadcrumbs items={breadcrumbs} />
	    <div>
		    {children}
	    </div>
    </ComponentContainer>
  );
};

export default ScreenHeaderWithBreadcrumbs;
