import React from "react";
import ParagraphsListRenderer from "../ParagraphsListRenderer";

import {
	CustomListItemContainer,
	CustomListItemsWrapper,
	DefaultListItemContainer,
	DefaultListItemsWrapper,
	ListTitleText,
	ListContainer,
	ListItemSymbolText
} from "./styles";

const ListItemsRenderer = ({content}) => {
	const listType = content.listType || 'circle';
	const title = content.listTitle || '';

	const listStyleItemMap = {
		'custom': 'none',
		'circle': 'circle',
		'number': 'decimal'
	};

	const listTypeSymbol = listStyleItemMap[content.listType || 'circle'];
	const RenderedListItemContainer = listType === 'custom' ? CustomListItemContainer : DefaultListItemContainer;
	const RenderedListItemsWrapper = listType === 'custom' ? CustomListItemsWrapper : DefaultListItemsWrapper;

	return (
		<ListContainer>
			{title.length > 0 && (
				<ListTitleText>{title}</ListTitleText>
			)}
			<RenderedListItemsWrapper listTypeSymbol={listTypeSymbol}>
				{content.items.map((listItem, listItemIndex) => (
					<RenderedListItemContainer key={listItemIndex}>
						{listType === 'custom' && (
							<ListItemSymbolText>{listItem.listItemSymbol}</ListItemSymbolText>
						)}
						<ParagraphsListRenderer content={listItem} />
					</RenderedListItemContainer>
				))}
			</RenderedListItemsWrapper>
		</ListContainer>
	)
};

export default ListItemsRenderer;
