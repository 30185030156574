import styled from "styled-components";
import {ParagraphItemText} from "../../../../Layout/DynamicContentRenderer/ParagraphsListRenderer/styles";

import {
	ArticleItemContainer,
	ArticleTitle
} from "../../../../../static-screens/LegalScreens/components/LegalScreenDocumentArticlesContent/styles";

export const ComponentContainer = styled.div`
  display: flex;
	flex-direction: column;
  row-gap: 20px;
	
	${ParagraphItemText} {
		font-size: 14px;
	}
	
	${ArticleItemContainer} ${ArticleTitle} {
		font-size: 18px;
	}
`;

