import Environment from "../Environment";
import {DEVELOPMENT, PRODUCTION, STAGING} from "../Environment/EnvironmentConstants";

import {
	CAREER_DETAIL_ROUTE,
	PREMIUM_BUSINESS_ROUTE,
	PREMIUM_CARD_COLLECTION_ROUTE,
	PREMIUM_CARD_DETAILS_ROUTER_ROUTE,
	PREMIUM_HOMEPAGE_ROUTE,
	PREMIUM_LIFESTYLE_ROUTE,
	PREMIUM_ORDER_CARD_ROUTE
} from "./NavigationConstants";

import StaticPageLayout from "../Layouts/StaticPageLayout";
import PremiumScreensHeader from "../Layouts/StaticPageLayout/components/Headers/PremiumScreensHeader";
import PremiumScreensFooter from "../Layouts/StaticPageLayout/components/Footers/PremiumScreensFooter";

const DEFAULT_OPTIONS = {
	layout: StaticPageLayout,
	exact: true,
}

const ALL_ENVIRONMENTS_OVERRIDE = {
	[CAREER_DETAIL_ROUTE]: {
		exact: false,
	},
	[PREMIUM_HOMEPAGE_ROUTE]: {
		headerComponent: PremiumScreensHeader,
		footerComponent: PremiumScreensFooter,
		headerProps: {
			type: 'dark',
		}
	},
	[PREMIUM_LIFESTYLE_ROUTE]: {
		headerComponent: PremiumScreensHeader,
		footerComponent: PremiumScreensFooter,
	},
	[PREMIUM_BUSINESS_ROUTE]: {
		headerComponent: PremiumScreensHeader,
		footerComponent: PremiumScreensFooter,
	},
	[PREMIUM_CARD_COLLECTION_ROUTE]: {
		headerComponent: PremiumScreensHeader,
		footerComponent: PremiumScreensFooter,
	},
	[PREMIUM_CARD_DETAILS_ROUTER_ROUTE]: {
		headerComponent: PremiumScreensHeader,
		footerComponent: PremiumScreensFooter,
	},
	[PREMIUM_ORDER_CARD_ROUTE]: {
		headerComponent: PremiumScreensHeader,
		footerComponent: PremiumScreensFooter,
	}
};

const DEVELOPMENT_OVERRIDES = {};
const STAGING_OVERRIDES = {};
const PRODUCTION_OVERRIDES = {};

const OVERRIDE_BY_ENVIRONMENT_TYPE = {
	[DEVELOPMENT]: DEVELOPMENT_OVERRIDES,
	[STAGING]: STAGING_OVERRIDES,
	[PRODUCTION]: PRODUCTION_OVERRIDES,
}

export function getRouteOptions(routeName){
	const environmentType = Environment.environmentType;
	const overrideObject = OVERRIDE_BY_ENVIRONMENT_TYPE[environmentType];

	let routeOptions = {...DEFAULT_OPTIONS};
	if(ALL_ENVIRONMENTS_OVERRIDE.hasOwnProperty(routeName)){
		routeOptions = {
			...routeOptions,
			...ALL_ENVIRONMENTS_OVERRIDE[routeName],
		}
	}

	if(overrideObject.hasOwnProperty(routeName)){
		routeOptions = {
			...routeOptions,
			...overrideObject[routeName],
		}
	}

	return routeOptions;
}
