import React from 'react';
import styled from "styled-components";

import Typography from "../../../Typography";
import Icon from "../../Icon";
import Surface from "../../Surface";

import {ButtonLink, DefaultButtonSurface} from "./styles";

const ButtonContainer = styled.div`
  position: relative;
  display: inline-flex;
	
  ${Surface.Primary}, 
  ${Surface.Light}, 
  ${Surface.LightGray}, 
  ${Surface.LightGradient}, 
  ${Surface.Dark}, 
  ${Surface.DarkGradient},
  ${Surface.Premium} {
    background-color: transparent;
    --base-button-background-color: var(--surface-button-background-color);
    --base-button-text-color: var(--surface-button-text-color);
  }
`;

const DefaultButton = ({
	children,
  text,
	buttonType = 'solid',
	textTransform,
  iconName,
	iconPosition,
	rounded=true,
	iconProps = {},
	surfaceComponent: SurfaceComponent = null,
	textComponent: TextComponent = Typography.ButtonText,
  ...props
}) => {
	const ButtonSurfaceComponent = SurfaceComponent !== null ? SurfaceComponent : DefaultButtonSurface;
	const isReversed = iconPosition === 'left';

	const hasIcon = (iconName || '').length > 0 ||
		(iconProps.hasOwnProperty('name') && (iconProps.name || '').length > 0);

	if(!textTransform) {
		if(TextComponent !== Typography.ButtonText) {
			textTransform = 'normal';
		} else {
			textTransform = 'uppercase'
		}
	}

	return (
		<ButtonContainer>
			<ButtonSurfaceComponent rounded={rounded}>
				<ButtonLink textTransform={textTransform} reversed={isReversed} rounded={rounded} data-type={buttonType} {...props}>
					<TextComponent>{children}{text}</TextComponent>
					{hasIcon === true && (
						<Icon name={iconName} size={16} {...iconProps} />
					)}
				</ButtonLink>
			</ButtonSurfaceComponent>
		</ButtonContainer>
	);
};

export default DefaultButton;
