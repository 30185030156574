import React, {useContext, useState} from 'react';

import Surface from "../../../../../../components/Atomic/Surface";
import CompanyLogo from "../../../../../../components/Atomic/Branding/CompanyLogo";
import DefaultButton from "../../../../../../components/Atomic/Buttons/DefaultButton";

import {CONTACT_US_ROUTE, HOMEPAGE_ROUTE} from "../../../../../Navigation/NavigationConstants";
import {LocalisationContext} from "../../../../../Providers/SharedProviders/LocalisationProvider";
import {useScrollPosition} from "../../../../../Helpers/useScrollPosition";

import ResponsiveMenu from "../components/ResponsiveMenu";
import MenuSection from "../components/MenuSection";
import useMenuItems from "../useMenuItems";

import {
	HeaderContainer,
	LogoWrapper,
	MenuSectionsWrapper,
	StyledContentSizeLimiter
} from "./styles";

const HEADER_TYPE_TO_LOGO_VARIANT = {
	default: 'primary',
	'dark-transparent': 'white'
}

const DefaultHeader = ({type = 'default'}) => {
	const {translations} = useContext(LocalisationContext);
	const [windowIsScrolled, setWindowIsScrolled] = useState(false);
	const items = useMenuItems();

	useScrollPosition(({currPos}) => {
		setWindowIsScrolled(currPos.y !== 0);
	}, [])

	return (
    <HeaderContainer data-header-type={type} data-scrolled={windowIsScrolled}>
	    <StyledContentSizeLimiter maxWidth={'lg'}>
		    <MenuSectionsWrapper>
					<LogoWrapper to={HOMEPAGE_ROUTE}>
						<CompanyLogo colorVariant={HEADER_TYPE_TO_LOGO_VARIANT[type]} />
					</LogoWrapper>
			    <ResponsiveMenu breakpoint={'sm'}>
				    <MenuSection
					    items={items}
					    buttonComponent={
						    <DefaultButton surfaceComponent={Surface.Primary} to={CONTACT_US_ROUTE}>
							    {translations.sections.header.contactUsButton}
						    </DefaultButton>
					    }
				    />
			    </ResponsiveMenu>
		    </MenuSectionsWrapper>
	    </StyledContentSizeLimiter>
    </HeaderContainer>
  );
};

export default DefaultHeader;
