import React, {useContext} from "react";
import {LocalisationContext} from "../../library/Providers/SharedProviders/LocalisationProvider";

import ContentSizeLimiter from "../../components/UI/Containers/ContentSizeLimiter";
import ScreenHeaderWithBreadcrumbs from "../../components/Layout/ScreenHeaderWithBreadcrumbs";

import OfficialLogos from './components/OfficialLogos'
import LogosToAvoid from "./components/LogosToAvoid";
import ColorPalette from "./components/ColorPalette";
import {useLogosToAvoid, useOfficialLogos} from "./useLogoItems";

import {ContentContainer, ScreenTitle} from "./styles";

const LogoGuidelinesScreen = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.logoGuidelines;

	const logosToAvoid = useLogosToAvoid();
	const officialLogos = useOfficialLogos();

	const breadcrumbs = [
		translations.staticScreens.commonSections.breadcrumbs.home,
		translationsObject.screenTitle
	];

	return (
		<ContentSizeLimiter>
			<ContentContainer>
				<ScreenHeaderWithBreadcrumbs breadcrumbs={breadcrumbs}>
					<ScreenTitle>{translationsObject.screenTitle}</ScreenTitle>
				</ScreenHeaderWithBreadcrumbs>

				<OfficialLogos logos={officialLogos} />
				<ColorPalette />
				<LogosToAvoid logos={logosToAvoid} />
			</ContentContainer>
		</ContentSizeLimiter>
	)
}

export default LogoGuidelinesScreen;
