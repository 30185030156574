import styled from 'styled-components';
import {rowSpacingSize, Text} from "../styles";

export const ListContainer = styled.div`
  display: grid;
  grid-row-gap: ${rowSpacingSize / 2}px;
`;

export const ListTitleText = styled(Text)``;

export const DefaultListItemsWrapper = styled.ul`
	display: grid;
	list-style-type: ${props => props.listTypeSymbol};
	grid-row-gap: ${rowSpacingSize / 2}px;
	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 20px;
`;

export const CustomListItemsWrapper = styled(DefaultListItemsWrapper)`
	padding-inline-start: 0;
`;

export const DefaultListItemContainer = styled.li``;
export const CustomListItemContainer = styled.li`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 10px;
`;

export const ListItemSymbolText = styled(Text)`
	color: #7e7e7e;
`;
