import React, {useContext, useState} from 'react';
import {Tab} from "@material-ui/core";
import {TabContext, TabList} from "@material-ui/lab";

import ContentSizeLimiter from "../../../../UI/Containers/ContentSizeLimiter";
import DefaultButton from "../../../../Atomic/Buttons/DefaultButton";

import {LocalisationContext} from "../../../../../library/Providers/SharedProviders/LocalisationProvider";

import useOptions from "../../useOptions";

import {
	ComponentOverlay,
	ButtonsWrapper,
	ButtonsContainer,
	ComponentContainer,
	ContentContainer,
	TabContent,
	TabsWrapper
} from "./styles";

import DetailsTabContent from "../DetailsTabContent";
import AboutTabContent from "../AboutTabContent";
import ConsentTabContent from "../ConsentTabContent";
import Surface from "../../../../Atomic/Surface";

const CookieBannerContent = (props) => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.sections.cookieBanner;

	const {onSave, onAcceptAll} = props;

	const [tabActive, setTabActive] = useState(0);
	const cookieOptions = useOptions(props);

	return (
		<ComponentOverlay>
			<ComponentContainer>
				<ContentContainer>
					<TabsWrapper>
						<ContentSizeLimiter>
							<TabContext value={tabActive}>
								<TabList
									onChange={(_, value) => setTabActive(value)}
									textColor="primary"
									indicatorColor="primary"
								>
									<Tab value={0} label={translationsObject.tabs.consent.title} />
									<Tab value={1} label={translationsObject.tabs.details.title} />
									<Tab value={2} label={translationsObject.tabs.about.title} />
								</TabList>

								<TabContent value={0}>
									<ConsentTabContent />
								</TabContent>
								<TabContent value={1}>
									<DetailsTabContent options={cookieOptions} />
								</TabContent>
								<TabContent value={2}>
									<AboutTabContent />
								</TabContent>
							</TabContext>
						</ContentSizeLimiter>
					</TabsWrapper>

					<ButtonsContainer>
						<ContentSizeLimiter>
							<ButtonsWrapper>
								<React.Fragment>
									{tabActive !== 1 && (
										<DefaultButton surfaceComponent={Surface.Primary} roundness={0} mode={'outlined'} to={''} onClick={() => setTabActive(1)}>
											{translationsObject.customizeButtonText}
										</DefaultButton>
									)}

									{tabActive === 1 && (
										<DefaultButton  surfaceComponent={Surface.Primary} roundness={0} mode={'outlined'} to={''} onClick={onSave}>
											{translationsObject.acceptSelectedButtonText}
										</DefaultButton>
									)}
								</React.Fragment>

								<DefaultButton  surfaceComponent={Surface.Primary} roundness={0} uppercase textColor={'white'} to={''} onClick={() => onAcceptAll()}>
									{translationsObject.acceptAllButtonText}
								</DefaultButton>
							</ButtonsWrapper>
						</ContentSizeLimiter>
					</ButtonsContainer>
				</ContentContainer>
			</ComponentContainer>
		</ComponentOverlay>
	)
}

export default CookieBannerContent;
