import React from 'react';

import {BreadcrumbsWrapper, BreadcrumbsText,} from "./styles";
import {ChevronRight} from "@material-ui/icons";

const Breadcrumbs = ({items}) => {
  return (
  	<BreadcrumbsWrapper>
		  {items.map((item, index) =>
			  <React.Fragment>
				  <BreadcrumbsText>{item}</BreadcrumbsText>
				  {index < items.length -1 && (
					  <ChevronRight fontSize={"inherit"} />
				  )}
			  </React.Fragment>
		  )}
	  </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
