import {useContext, useMemo} from "react";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import riskManagementIcon from './assets/fraud-managemen-icon.png';
import pciDssIcon from './assets/pci-dss-icon.png';
import encryptionIcon from './assets/encryption-icon.png';
import customizableSolutionIcon from './assets/customizable-solution-icon.png';

export default function useItems(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.technologyScreen.upgradeSection.items;

	return useMemo(() => [
		{...translationsObject.riskManagement, icon: riskManagementIcon},
		{...translationsObject.pciDss, icon: pciDssIcon},
		{...translationsObject.encryption, icon: encryptionIcon},
		{...translationsObject.customizableSolution, icon: customizableSolutionIcon},
	], [translationsObject]);
}
