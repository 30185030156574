import React from 'react';
import styled from "styled-components";
import {iconMap} from './useIcon';

const StyledIcon = styled.img`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const Icon = ({name, colorVariation = 'white', size= 60, alt= ''}) => {
  return (
    <StyledIcon src={iconMap[colorVariation][name]} alt={alt} size={size} />
  );
};

export default Icon;
