import React, {useState} from 'react';

import Surface from "../../../../../../components/Atomic/Surface";
import DefaultButton from "../../../../../../components/Atomic/Buttons/DefaultButton";
import CompanyLogo from "../../../../../../components/Atomic/Branding/CompanyLogo";
import {PREMIUM_HOMEPAGE_ROUTE} from "../../../../../Navigation/NavigationConstants";

import {
	HeaderContainer,
	LogoWrapper,
	MenuSectionsWrapper,
	StyledContentSizeLimiter
} from "./styles";

import MenuSection from "./../components/MenuSection";
import ResponsiveMenu from "./../components/ResponsiveMenu";
import useMenuItems from "./../useMenuItems";
import {useScrollPosition} from "../../../../../Helpers/useScrollPosition";


const HEADER_TYPE_TO_LOGO_VARIANT = {
	default: 'dark',
	'dark': 'white',
}

const PremiumScreenHeader = ({type = 'default'}) => {
	const [windowIsScrolled, setWindowIsScrolled] = useState(false);
	const items = useMenuItems();

	useScrollPosition(({currPos}) => {
		setWindowIsScrolled(currPos.y !== 0);
	}, [])

	const HeaderSurface = type === 'default' ? Surface.Light : Surface.Dark;

	return (
		<HeaderSurface>
			<HeaderContainer data-header-type={type} data-scrolled={windowIsScrolled}>
				<StyledContentSizeLimiter maxWidth={'lg'}>
					<MenuSectionsWrapper>
						<LogoWrapper to={PREMIUM_HOMEPAGE_ROUTE}>
							<CompanyLogo variant={'premium'} colorVariant={HEADER_TYPE_TO_LOGO_VARIANT[type]} />
						</LogoWrapper>
						<ResponsiveMenu breakpoint={'sm'}>
							<MenuSection
								items={items}
								buttonComponent={
									<DefaultButton
										rounded={'false'}
										iconPosition={'left'}
										iconProps={{colorVariation: type === 'default' ? 'white' : 'dark', size: 16}}
										iconName={'PapelIcons-SquaresFourRound'}
									>
										Join
									</DefaultButton>
								}
							/>
						</ResponsiveMenu>
					</MenuSectionsWrapper>
				</StyledContentSizeLimiter>
			</HeaderContainer>
		</HeaderSurface>
	);
};

export default PremiumScreenHeader;
