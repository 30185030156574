import {lazy} from "react";
import Environment from "../Environment";
import {DEVELOPMENT, PRODUCTION, STAGING} from "../Environment/EnvironmentConstants";

import {
	ABOUT_US_ROUTE,
	CONTACT_US_ROUTE,
	HOMEPAGE_ROUTE,
	LOGO_GUIDELINES_ROUTE,
	NOT_FOUND_ROUTE,
	PRIVACY_POLICY_ROUTE,
	SECURITY_ROUTE,
	ECOMMERCE_ROUTE,
	TOS_ROUTE,
	COOKIE_POLICY_ROUTE,
	CAREER_ROUTE,
	CAREER_DETAIL_ROUTE,
	TECHNOLOGY_ROUTE,
	POS_ROUTE,
	WALLET_ROUTE,
	PERSONAL_FAQ_ROUTE,
	CORPORATE_FAQ_ROUTE,
	GATEWAY_ROUTE,
	CORPORATE_HOMEPAGE_ROUTE,
	PRIVACY_POLICY_JOB_APPLICANTS_ROUTE,
	PREMIUM_ORDER_CARD_ROUTE,
	PREMIUM_CARD_COLLECTION_ROUTE,
	PREMIUM_CARD_DETAILS_ROUTER_ROUTE,
	PREMIUM_HOMEPAGE_ROUTE,
	PREMIUM_LIFESTYLE_ROUTE,
	PREMIUM_BUSINESS_ROUTE,
	QR_PAYMENTS_ROUTE, INTERNATIONAL_MERCHANT_ROUTE, SMART_POS_ROUTE, ALL_IN_ONE_POS_ROUTE, PAYMENT_GATEWAY_ROUTE
} from "./NavigationConstants";

import LogoGuidelinesScreen from "../../static-screens/LogoGuidelinesScreen";
import TechnologyScreen from "../../static-screens/TechnologyScreen";

const SecurityScreen = lazy(() => import ("../../static-screens/SecurityScreen"));

const NotFound = lazy(() => import("../../screens/Common/NotFoundScreen"));
const AboutUsScreen = lazy(() => import ("../../screens/Common/AboutUsScreen"));
const ContactScreen = lazy(() => import ("../../screens/Common/ContactScreen"));
const CareerScreen = lazy(() => import ("../../screens/Common/Career/CareerScreen"));
const CareerDetailsScreen = lazy(() => import ("../../screens/Common/Career/CareerDetailsScreen"));
const FAQScreen = lazy(() => import ("../../screens/Common/FAQScreen"));

const PersonalHomepage = lazy(() => import ("../../screens/Personal/HomepageScreen"));
const WalletScreen = lazy(() => import ("../../screens/Personal/WalletScreen"));

const GatewayScreen = lazy(() => import ("../../screens/Corporate/GatewayScreen"));
const PosScreen = lazy(() => import ("../../screens/Corporate/PosScreen"));
const ECommerceScreen = lazy(() => import ("../../screens/Corporate/E-CommerceScreen"));
const CorporateHomepageScreen = lazy(() => import ("../../screens/Corporate/HomepageScreen"));

const SmartPosScreen = lazy(() => import ("../../screens/Corporate/SmartPosScreen"));
const QrPaymentScreen = lazy(() => import ("../../screens/Corporate/QrPaymentScreen"));
const AllInOnePosScreen = lazy(() => import ("../../screens/Corporate/AllInOnePosScreen"));
const PaymentGatewayScreen = lazy(() => import ("../../screens/Corporate/PaymentGatewayScreen"));
const InternationalMerchantScreen = lazy(() => import ("../../screens/Corporate/InternationalMerchantScreen"));

/**
 * Premium Screens
 */

const PremiumHomepageScreen = lazy(() => import ("../../screens/Premium/HomepageScreen"));
const PremiumCardCollection = lazy(() => import ("../../screens/Premium/CardCollection"));
const PremiumCardDetails = lazy(() => import ("../../screens/Premium/CardDetailsScreen"));
const PremiumOrderCardScreen = lazy(() => import ("../../screens/Premium/OrderCardScreen"));
const PremiumExperienceScreen = lazy(() => import ("../../screens/Premium/ExperienceScreen"));

/**
 * Legal Screens
 */
const TermsOfServiceScreen =  lazy(() => import ("../../static-screens/LegalScreens/TermsOfServiceScreen"));
const CookiePolicyScreen =  lazy(() => import ("../../static-screens/LegalScreens/CookiePolicyScreen"));
const PrivacyPolicyScreen =  lazy(() => import ("../../static-screens/LegalScreens/PrivacyPolicyScreen"));
const PrivacyPolicyJobApplicationtsScreen =  lazy(() => import ("../../static-screens/LegalScreens/PrivacyPolicyForJobApplicationts"));

const DEFAULT_MAPPING = {
	[HOMEPAGE_ROUTE]: PersonalHomepage,
	[ABOUT_US_ROUTE]: AboutUsScreen,
	[CONTACT_US_ROUTE]: ContactScreen,
	[SECURITY_ROUTE]: SecurityScreen,
	[LOGO_GUIDELINES_ROUTE]: LogoGuidelinesScreen,

	[ECOMMERCE_ROUTE]: ECommerceScreen,
	[CAREER_ROUTE]: CareerScreen,
	[CAREER_DETAIL_ROUTE]: CareerDetailsScreen,
	[TECHNOLOGY_ROUTE]: TechnologyScreen,
	[POS_ROUTE]: PosScreen,

	[TOS_ROUTE]: TermsOfServiceScreen,
	[COOKIE_POLICY_ROUTE]: CookiePolicyScreen,
	[PRIVACY_POLICY_ROUTE]: PrivacyPolicyScreen,
	[NOT_FOUND_ROUTE]: NotFound,

	[WALLET_ROUTE]: WalletScreen,
	[PERSONAL_FAQ_ROUTE]: FAQScreen,
	[CORPORATE_FAQ_ROUTE]: FAQScreen,
	[GATEWAY_ROUTE]: GatewayScreen,
	[CORPORATE_HOMEPAGE_ROUTE]: CorporateHomepageScreen,
	[PRIVACY_POLICY_JOB_APPLICANTS_ROUTE]: PrivacyPolicyJobApplicationtsScreen,

	[SMART_POS_ROUTE]: SmartPosScreen,
	[QR_PAYMENTS_ROUTE]: QrPaymentScreen,
	[ALL_IN_ONE_POS_ROUTE]: AllInOnePosScreen,
	[PAYMENT_GATEWAY_ROUTE]: PaymentGatewayScreen,
	[INTERNATIONAL_MERCHANT_ROUTE]: InternationalMerchantScreen,

	[PREMIUM_HOMEPAGE_ROUTE]: PremiumHomepageScreen,
	[PREMIUM_LIFESTYLE_ROUTE]: PremiumExperienceScreen,
	[PREMIUM_BUSINESS_ROUTE]: PremiumExperienceScreen,
	[PREMIUM_ORDER_CARD_ROUTE]: PremiumOrderCardScreen,
	[PREMIUM_CARD_DETAILS_ROUTER_ROUTE]: PremiumCardDetails,
	[PREMIUM_CARD_COLLECTION_ROUTE]: PremiumCardCollection,
}

const DEVELOPMENT_OVERRIDES = {};
const STAGING_OVERRIDES = {};
const PRODUCTION_OVERRIDES = {};

const OVERRIDE_BY_ENVIRONMENT_TYPE = {
	[DEVELOPMENT]: DEVELOPMENT_OVERRIDES,
	[STAGING]: STAGING_OVERRIDES,
	[PRODUCTION]: PRODUCTION_OVERRIDES,
}

export function getRouteMappingsToScreen(){
	const environmentType = Environment.environmentType;
	const overrideObject = OVERRIDE_BY_ENVIRONMENT_TYPE[environmentType];

	let routeMappings = {...DEFAULT_MAPPING};
	Object.keys(overrideObject).forEach((routeName) => {
		if(DEFAULT_MAPPING.hasOwnProperty(routeName)){
			routeMappings[routeName] = overrideObject[routeName];
		}
	})

	return routeMappings;
}
