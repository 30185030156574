import styled from "styled-components";
import {SectionIcon} from "../../styles";

export const SectionContainer = styled.div`
  padding: 0 30px;
  display: grid;
  grid-row-gap: 70px;
  
  ${SectionIcon} {
    background-color: #2BD67B;
  }
`;

export const SectionDescriptionText = styled.div`
  font-size: 20px;
  line-height: calc(34 / 20);
  color: #81838C;
`;

export const SectionItemsWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, min(375px, calc((100% - 3 * 30px) / 4)));
  column-gap: 30px;
  
  @media all and ${({theme: {device}}) => device.md} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @media all and ${({theme: {device}}) => device.sm} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and ${({theme: {device}}) => device.xs} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const SectionItemContainer = styled.div`
  display: grid;
  grid-row-gap: 30px;
  grid-template-rows: max-content max-content 1fr;
  
  background-color: #101C3D;
  border-radius: 10px;
  padding: 40px 30px;
  color: white;
  
  text-align: center;
`;

export const SectionItemIcon = styled.div`
  justify-self: center;
  background-color: white;
  background-image: url(${props => props.icon});
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  width: 50px;
  height: 50px;
`;

export const SectionItemTitle = styled.div`
  font-size: 26px;
`;

export const SectionItemDescription = styled.div`
   color: #8B98BE; 
`;
