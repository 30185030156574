import React from 'react';

import {ThemeProvider} from '../../Theme/ThemeProvider';
import {LocalisationProvider} from "./LocalisationProvider";
import {CookieBannerProvider} from "../../../components/Sections/CookieBanner/providers/CookieProviders";

const GlobalProviders = ({children}) => {

  return (
      <ThemeProvider>
				<LocalisationProvider>
					<CookieBannerProvider>
						{children}
					</CookieBannerProvider>
				</LocalisationProvider>
      </ThemeProvider>
  );
};

export default GlobalProviders;
