import React, {Suspense} from "react";
import { Route } from "react-router-dom";

import URLHelper from "../../Helpers/URLHelper";
import ContainerWithHeaderSpaceFix from "../../../components/Layout/ContainerWithHeaderSpaceFix";

import DefaultHeader from "./components/Headers/DefaultHeader";
import DefaultFooter from "./components/Footers/DefaultFooter";

import {ContentContainer, StaticPageLayoutWrapper} from "./styles";

const StaticPageLayout = ({
	component: Component,
	hasHeader = true,
	hasFooter = true,
	headerProps = {},
	headerComponent: PageHeaderComponent = DefaultHeader,
	footerComponent: PageFooterComponent = DefaultFooter,
	...rest
}) => {
  const {hideHeader, hideFooter} = URLHelper.getUrlParams();
  const isHeaderDisplayed = hasHeader === true && hideHeader !== 'true';
  const isFooterDisplayed = hasFooter === true && hideFooter !== 'true';

	const HeaderFixContainer = isHeaderDisplayed ? ContainerWithHeaderSpaceFix : React.Fragment;

	return (
    <Route {...rest} render={matchProps => (
      <StaticPageLayoutWrapper>
        {isHeaderDisplayed === true && <PageHeaderComponent {...headerProps} />}
	      <Suspense fallback={React.Fragment}>
		      <ContentContainer>
			      <HeaderFixContainer>
				      <Component {...matchProps} />
			      </HeaderFixContainer>
	        </ContentContainer>
	        {isFooterDisplayed === true && <PageFooterComponent />}
	      </Suspense>
      </StaticPageLayoutWrapper>
    )} />
  );
};

export default StaticPageLayout;
