import intl from "react-intl-universal";
import {APP_LANGUAGE_STORAGE_KEY, TRANSLATIONS} from "./LocalizationConstants";
import Environment from "../Environment";

export default function determineInitialLocale(){
	let currentLocale = intl.determineLocale({
		urlLocaleKey: APP_LANGUAGE_STORAGE_KEY,
		cookieLocaleKey: APP_LANGUAGE_STORAGE_KEY,
		localStorageLocaleKey: APP_LANGUAGE_STORAGE_KEY
	});

	if (TRANSLATIONS.hasOwnProperty(currentLocale) === false) {
		currentLocale = Environment.defaultLanguage;
	}

	return currentLocale;
}
