import styled from "styled-components";

export const ComponentContainer = styled.div`
  display: flex;
	flex-direction: column;
  row-gap: 20px;
`;

export const DetailsTabContentContainer = styled.div`
  display: flex;
	column-gap: 20px;

  padding: 0 10px 20px 0;
  border-bottom: 1px solid #4c4c4c;
	
	&:last-of-type {
		border-bottom: none;
	}
`;

export const DetailsTabContentTextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
	
	flex: 1;
`;

export const DetailsTabCookieTypeTitle = styled.div`
	font-size: 16px;
  font-weight: bold;
	color: black;
`;

export const DetailsTabCookieTypeText = styled.div`
    
`;
