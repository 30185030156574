import React from "react";
import {ParagraphItemText, ParagraphsListItemsWrapper} from "./styles";
import {replaceTexts} from "../../../../library/Translate/translation-proxy";

const ParagraphsListRenderer = ({content}) => {
	let paragraphs = [];
	if(content.hasOwnProperty('paragraphs')){
		paragraphs = [...content.paragraphs]
	} else if (typeof content === "string"){
		paragraphs = [content];
	}

	return (
		<ParagraphsListItemsWrapper>
			{paragraphs.map((paragraph, paragraphIndex) => (
				<ParagraphItemText key={paragraphIndex} dangerouslySetInnerHTML={{ __html: replaceTexts(paragraph)}} />
			))}
		</ParagraphsListItemsWrapper>
	);
}

export default ParagraphsListRenderer;
