import React, {useContext} from 'react';
import styled from "styled-components";

import Typography from "../../../../../../components/Typography";
import Surface from "../../../../../../components/Atomic/Surface";
import CompanyLogo from "../../../../../../components/Atomic/Branding/CompanyLogo";
import ContentSizeLimiter from "../../../../../../components/UI/Containers/ContentSizeLimiter";

import {LocalisationContext} from "../../../../../Providers/SharedProviders/LocalisationProvider";

import useLinks from "./../useLinks";
import LinksSection from "../components/LinksSection";

const ComponentContainer = styled(Surface.Dark)`
  background-color: #111111;
`;

const ContentContainer = styled.div`
  display: grid;
	grid-row-gap: ${props => props.theme.sizes.xl}px;
	padding: ${props => props.theme.sizes.xxl}px 0;
	
	@media all and ${({theme: {device}}) => device.sm} {
    padding: ${props => props.theme.sizes.xl}px 0;
  }
`;

const DisclaimersWrapper = styled.div`
  display: flex;
	flex-direction: column;
	row-gap: ${props => props.theme.sizes.sm}px;
`;

const LogoSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.sizes.lg}px;
	
  max-width: 410px;
  
  @media all and ${({theme: {device}}) => device.sm} {
  	max-width: initial;
  }
`;

const LogoAndLinksWrapper = styled.div`
  display: flex;
	justify-content: space-between;
	column-gap: ${props => props.theme.sizes.lg}px;
	
	img {
		max-width: 266px;
	}

	@media all and ${({theme: {device}}) => device.sm} {
		flex-direction: column;
		row-gap: ${props => props.theme.sizes.lg}px;
	}
`;

const PremiumScreensFooter = () => {
	const {translations} = useContext(LocalisationContext);
	const translationObjects = translations.sections.footers.premium;

	const linkSections = useLinks();

	return (
		<ComponentContainer>
			<ContentSizeLimiter>
				<ContentContainer>
					<LogoAndLinksWrapper>
						<LogoSectionWrapper>
							<CompanyLogo variant={'premium'} />
							<Typography.Copy size={'sm'}>{translationObjects.servicesText}</Typography.Copy>
						</LogoSectionWrapper>
						<LinksSection sections={linkSections} />
					</LogoAndLinksWrapper>

					<DisclaimersWrapper>
						{translationObjects.disclaimerParagraphs.map((paragraph, index) => (
							<Typography.Copy size={'sm'} dangerouslySetInnerHTML={{__html: paragraph}} />
						))}
					</DisclaimersWrapper>
				</ContentContainer>
			</ContentSizeLimiter>
		</ComponentContainer>
	);
};

export default PremiumScreensFooter;
