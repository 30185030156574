import React from 'react';
import {Route} from "react-router-dom";

const ContextAwareRoute = ({requiresAuthentication = false, component: Component, layout: Layout, ...rest}) => {
	const ComponentRendered = () => <Route {...rest} render={matchProps => (
		<Layout component={Component} {...matchProps} {...rest} />
	)}/>;

	return (
		<Route
			{...rest}
			render={rProps => <ComponentRendered rProps={rProps} />}
		/>
	);
};

export default ContextAwareRoute;
