import styled from 'styled-components';

export const AvoidLogosContainer = styled.div`
  display: grid;
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  grid-template-columns: 1fr 1fr 1fr;

  @media all and ${({theme: {device}}) => device.sm} {
    grid-template-columns: 1fr 1fr;
  }

  @media all and ${({theme: {device}}) => device.xs} {
    grid-template-columns: 1fr;
  }
`;
