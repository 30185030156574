import React from "react";

import {Table, Row, Cell, HeaderCell} from "./styles";

const TableRenderer = ({content}) => {
	const tableHeaders = content.tableHeaders || [];
	const tableRows = content.tableRows || [];

	return (
		<Table>
			{tableHeaders.length > 0 && (
				<Row>
					{tableHeaders.map((header, index) => (
						<HeaderCell key={`header-${index}`}>{header}</HeaderCell>
					))}
				</Row>
			)}

			{tableRows.map((row, rowIndex) => (
				<Row key={`row-${rowIndex}`}>
					{row.map((rowCell, index) => (
						<Cell key={`row-${rowIndex}-cell-${index}`}>{rowCell}</Cell>
					))}
				</Row>
			))}
		</Table>
	)
};

export default TableRenderer;
