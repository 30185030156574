import styled from 'styled-components';
import {Link} from "react-router-dom";

import ContentSizeLimiter from "../../../../../../components/UI/Containers/ContentSizeLimiter";
import Surface from "../../../../../../components/Atomic/Surface";

export const HeaderContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-items: center;

  position: fixed;
  z-index: 3;
  height: 80px;

  background-color: #fff;
  box-shadow: 0 2px 10px 2px rgba(150, 150, 150, 0.12);
  width: 100%;

  &[data-header-type='dark'] {
    background-color: #1A1A1A;
    box-shadow: 0 2px 10px 2px rgba(31, 31, 31, 0.12);

    --button-text-color: #fff;
    ${Surface.Light} {
		  --button-text-color: #000;
	  }
  }
`;

export const StyledContentSizeLimiter = styled(ContentSizeLimiter)`
  width: 100%;
`;

export const MenuSectionsWrapper = styled.div`
  flex: 1;
  display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: ${props => props.theme.sizes.xl}px;
`;

export const LogoWrapper = styled(Link)`
  img {
    max-width: 266px;
  }
`;

