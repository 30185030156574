import React, {useMemo} from 'react';
import {KeyboardArrowDown} from "@material-ui/icons";

import {
	MenuItemContainer,
	MenuItemLink,
	MenuItemText,
	SubMenuContentContainer,
	SubMenuWrapper
} from "./styles";

import Typography from "../../../../../../../../../components/Typography";

const MenuItem = ({text, to, target, hasSubMenu = false, items}) => {
	const itemHasLink = to !== undefined || target !== undefined;

	const menuTextProps = useMemo(() => {
		if(itemHasLink === false) {
			return {};
		}

		return {
			to: to,
			target: target
		}
	}, [to, target, itemHasLink]);

	const MenuItemLinkComponent = itemHasLink === true ? MenuItemLink : React.Fragment;

  return (
    <MenuItemContainer withSubMenu={hasSubMenu}>
	    <MenuItemText>
		    <MenuItemLinkComponent {...menuTextProps}>{text}</MenuItemLinkComponent>
		    {hasSubMenu === true && (<KeyboardArrowDown fontSize={'inherit'} />)}
	    </MenuItemText>

	    {hasSubMenu === true && (
		    <SubMenuWrapper>
			    <SubMenuContentContainer>
				    {items.map((item, index) => (
					    <MenuItemText key={index}>
					      <MenuItemLink to={item.to}>
						      <Typography.ButtonText>{item.text}</Typography.ButtonText>
								</MenuItemLink>
					    </MenuItemText>
				    ))}
			    </SubMenuContentContainer>
		    </SubMenuWrapper>
	    )}
    </MenuItemContainer>
  );
};

export default MenuItem;
