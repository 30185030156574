import React, {useContext} from 'react';
import {LocalisationContext} from "../../../../../library/Providers/SharedProviders/LocalisationProvider";

import {CookieTitleText, TextWrapper} from "./styles";

const ConsentTabContent = () => {
  const {translations} = useContext(LocalisationContext);
  const translationsObject = translations.sections.cookieBanner.tabs.consent.content;

  return (
    <TextWrapper>
      <CookieTitleText>{translationsObject.title}</CookieTitleText>
      <div>{translationsObject.text}</div>
    </TextWrapper>
  );
};

export default ConsentTabContent;
