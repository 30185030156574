import {DEVELOPMENT, STAGING, PRODUCTION} from '../EnvironmentConstants';

const ENV_FILES_BY_BUILD_TYPE = {
  common: require('./../envs/common.json'),
  [DEVELOPMENT]: require('./../envs/development.json'),
  [STAGING]: require('./../envs/staging.json'),
  [PRODUCTION]: require('./../envs/production.json'),
};

export default function getBuildTypeEnvironmentVariables(buildType) {
  return Object.assign(
    {
      IS_DEVELOPMENT_ENVIRONMENT: buildType === DEVELOPMENT,
      IS_PRODUCTION_ENVIRONMENT: buildType === PRODUCTION,
      IS_STAGING_ENVIRONMENT: buildType === STAGING,
    },
    ENV_FILES_BY_BUILD_TYPE.common,
    ENV_FILES_BY_BUILD_TYPE[buildType],
  );
}
