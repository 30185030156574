import styled from 'styled-components';

export const LogosContainer = styled.div`
	display: grid;
	grid-column-gap: 70px;
	grid-row-gap: 70px;
	grid-template-columns: 1fr 1fr;
	
	@media all and ${({theme: {device}}) => device.xs} {
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}
`;

export const ButtonsContainer = styled.div`
	display: inline-block;
  vertical-align: bottom;
  line-height: normal;
  margin-bottom: 20px;
  margin-right: 30px;
`;

export const ButtonsContainerRight = styled.div`
  text-align: right;
`;

export const DownloadButtonContainer = styled.div`
	display: inline-block;
	color: ${props => props.color};
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	text-align: right;
	padding-left: 30px;
	text-transform: uppercase;
	cursor: pointer;
	
	& svg {
		padding-right: 10px;
		fill: ${props => props.color}
	}
`;
