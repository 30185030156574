import styled from 'styled-components';
import {rowSpacingSize, Text} from "../styles";

export const ParagraphsListItemsWrapper = styled.div`
	display: grid;
	grid-row-gap: ${rowSpacingSize}px;
`;

export const ParagraphItemText = styled(Text)``;




