export const DEVELOPMENT = 'development';
export const STAGING = 'staging';
export const PRODUCTION = 'production';

export const APP_REGION_CY = 'CY';
export const APP_REGION_AE = 'AE';
export const APP_REGION_COM = 'EU';

export const DOMAIN_TO_REGION_MAP = {
	[APP_REGION_COM]: [
		'papel.com',
		'localhost'
	],
	[APP_REGION_AE]: [
		'papel.cy'
	],
	[APP_REGION_CY]: [
		'papel.cy'
	]
}

export const HOSTNAME_TO_ENV_TYPE = {
	[DEVELOPMENT]: [
		"development.",
		"dev.",
		"localhost"
	],
	[STAGING]: [
		"stage.",
		"staging.",
	],
	[PRODUCTION]: [
		"preview.",
		"",
	]
}
