import React, { useState, useContext, createContext } from 'react';
import { useCookies } from 'react-cookie';

import { getExpirationDate } from '../helpers';
import {ALL_COOKIES, OPTIONAL_COOKIES, CONSENT_GIVEN_COOKIE_NAME} from "../CookieConstants";

const PreferencesContext = createContext();
const CookieBannerProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(ALL_COOKIES);

  let initialCookiesState = {};
  ALL_COOKIES.forEach(item => {
    initialCookiesState[item] = cookies[item] || false;
  });

  const [consents, setConsents] = useState(initialCookiesState);

  const toggleCookie = (cookie, value = false) => {
    if (value) {
      setCookie(cookie, value, { expires: getExpirationDate() });
    } else {
      removeCookie(cookie);
    }
  };

  const onSaveConsents = (cookies) => {
    let newCookies = {};

    toggleCookie(CONSENT_GIVEN_COOKIE_NAME, true);

    OPTIONAL_COOKIES
      .forEach(item => {
        toggleCookie(item, cookies[item]);
        newCookies[item] = cookies[item] || false;
      });

    setConsents({
      [CONSENT_GIVEN_COOKIE_NAME]: true,
      ...newCookies
    });
  };

  const onAcceptAll = () => {
    const expires = getExpirationDate();
    let newCookies = {};

    ALL_COOKIES
      .forEach(item => {
        newCookies[item] = true;
        setCookie(item, true, { expires });
      });

    setConsents(newCookies);
  };

  const context = {
    consents,
    onAcceptAll,
    onSaveConsents,
  };

  return (
    <PreferencesContext.Provider value={context}>
      {children}
    </PreferencesContext.Provider>
  );
};

const useCookieBanner = () => useContext(PreferencesContext);
export {CookieBannerProvider, useCookieBanner};
