import styled from 'styled-components';

export const ContentContainer = styled.div`
	display: grid;
	grid-row-gap: 100px;
	margin: 80px 0;

	@media all and ${({theme: {device}}) => device.xs} {
		grid-row-gap: 60px;
	}
`;

export const ScreenTitle = styled.div`
  font-size: 51px;
	line-height: 42px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;

	@media all and ${({theme: {device}}) => device.sm} {
		font-size: 43px;
		line-height: 37px;
	}
`;

export const BoxContainer = styled.div``;

export const ImageContainer = styled.div`
	border: 1px solid #dfdfdf;
	background-color: ${props => props.backgroundColor};
	height: 250px;
	line-height: 250px;
	background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-position: center; 
`;

export const TextContainer = styled.div`
	border: 1px solid #dfdfdf;
	background-color: ${props => props.backgroundColor};
	height: 250px;
	line-height: 250px;
  text-align: center;
`;

export const TextItems = styled.div`
	display: inline-block;
	text-align: left;
  vertical-align: middle;
  line-height: normal;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
`;

export const TextItem = styled.div`
	padding-bottom: 10px;
`;

export const BoxDescription = styled.div`
	color: #999fae;
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	padding-top: 30px;
	text-align: left;
	
	@media all and ${({theme: {device}}) => device.xs} {
		padding-bottom: 30px;
	}
`;

export const SectionTitle = styled.div`
	color: #19191a;
	font-size: 29px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: 51px;
	text-align: center;
`
