import styled from 'styled-components';

const rowSpacingSize = 20;
export const ComponentContainer = styled.div`
	display: grid;
	grid-row-gap: 50px;
`;

export const DocumentTitleText = styled.div`
	color: #0a2540;
	font-size: 35px;
	font-weight: 700;
	line-height: 62px;
`;

export const ArticlesWrapper = styled.div`
  display: grid;
	grid-row-gap: ${rowSpacingSize}px;
`;

export const ArticleTitle = styled.span`
	display: block;
	color: #343434;
	font-size: 24px;
	line-height: 32px;
	font-weight: bold;
`;

export const ArticleItemContainer = styled.div`
  display: grid;
	grid-row-gap: ${rowSpacingSize}px;
	
	& & ${ArticleTitle} {
		font-size: 19px;
	}
`;

export const ArticleText = styled.span`
  display: block;
  color: #adb5bd;
  color: #60666d;
  font-size: 17px;
  line-height: 32px;
`;

export const ExtraContentContentItemsWrapper = styled.div`
	display: grid;
	grid-row-gap: ${rowSpacingSize}px;
`;

export const ExtraContentContentItemContainer = styled.div`
	display: grid;
	grid-row-gap: calc(${rowSpacingSize}px / 2);
`;

export const ExtraContentItemTitleText = styled.div`
	color: #0a2540;
	font-size: 19px;
`;

