import React from 'react';
import DynamicContentRenderer from "../../../../components/Layout/DynamicContentRenderer";

import {
	ComponentContainer,
	DocumentTitleText,
	ArticlesWrapper,
	ArticleItemContainer,
	ArticleTitle,
	ArticleText,
	ExtraContentContentItemsWrapper,
	ExtraContentContentItemContainer,
	ExtraContentItemTitleText,
} from './styles';
import {replaceTexts} from "../../../../library/Translate/translation-proxy";

const ArticleExtraContent = ({article}) => {
	if(article.hasOwnProperty('extraContent') === false || (article.extraContent || []).length === 0)
		return React.Fragment;

	const customRenderers = {
		'sub-article': SubArticleRenderer
	};

	return (
		<ExtraContentContentItemsWrapper>
			{article.extraContent.map((extraContentItem, extraContentItemIndex) => (
				<ExtraContentContentItemContainer key={extraContentItemIndex}>
					{extraContentItem.hasOwnProperty('title') && (
						<ExtraContentItemTitleText>{extraContentItem.title}</ExtraContentItemTitleText>
					)}
					<DynamicContentRenderer content={extraContentItem} customRenderers={customRenderers} />
				</ExtraContentContentItemContainer>
			))}
		</ExtraContentContentItemsWrapper>
	)
};

const SubArticleRenderer = ({content}) => {
	const article = {...content, title: content['article-sub-title']};
	return (
		<ArticleItem {...{article}} />
	)
};

const ArticleItem = ({article}) => {
	return (
		<ArticleItemContainer>
			{article.hasOwnProperty('title') && (
				<ArticleTitle>{article.title}</ArticleTitle>
			)}
			{article.hasOwnProperty('text') && (
				<ArticleText>{replaceTexts(article.text)}</ArticleText>
			)}
			<ArticleExtraContent {...{article}} />
		</ArticleItemContainer>
	)
};

const LegalScreenDocumentArticlesContent = ({title, articles}) => {
  return (
    <ComponentContainer>
	    {title && (
		    <DocumentTitleText>{title}</DocumentTitleText>
	    )}
	    <ArticlesWrapper>
		    {articles.map((article, articleIndex) => (
			    <ArticleItem key={articleIndex} article={article} />
		    ))}
	    </ArticlesWrapper>
    </ComponentContainer>
  );
};

export default LegalScreenDocumentArticlesContent;
