import {useContext, useMemo} from "react";
import {LocalisationContext} from "../../library/Providers/SharedProviders/LocalisationProvider";
import DefaultTheme from "../../library/Theme/DefaultTheme";

import FullLogoBlue from "./assets/fullLogoBlue.png";
import FullLogoBlueSVG from "./assets/fullLogoBlue.svg";
import FullLogoWhite from "./assets/fullLogoWhite.png";
import FullLogoWhiteSVG from "./assets/fullLogoWhite.svg";
import FullLogoYellowWhite from "./assets/fullLogoYellowWhite.png";
import FullLogoYellowWhiteSVG from "./assets/fullLogoYellowWhite.svg";
import SmallLogoBlue from "./assets/smallLogoBlue.png";
import SmallLogoBlueSVG from "./assets/smallLogoBlue.svg";

import FullLogoOutlined from "./assets/fullLogoOutlined.png";
import FullLogoGradient from "./assets/fullLogoGradient.png";
import FullLogoBottomPadding from "./assets/fullLogoBottomPadding.png";
import FullLogoDissproportionalScale from "./assets/fullLogoDissproportionalScale.png";
import FullLogoShadow from "./assets/fullLogoShadow.png";
import FullLogoYellow from "./assets/fullLogoYellow.png";

export function useOfficialLogos(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.logoGuidelines;

	return useMemo(() => [
		{
			containerBackgroundColor: DefaultTheme.colors.white,
			text: translationsObject.productLogo,
			fileName: translationsObject.exports.FullLogoBlue,
			downloadButtonColor: '#080c17',
			logoPng: FullLogoBlue, logoSvg: FullLogoBlueSVG
		},
		{
			containerBackgroundColor: DefaultTheme.colors.white,
			text: translationsObject.logomark,
			fileName: translationsObject.exports.SmallLogoBlue,
			downloadButtonColor: '#080c17',
			logoPng: SmallLogoBlue, logoSvg: SmallLogoBlueSVG
		},
		{
			containerBackgroundColor: DefaultTheme.colors.black,
			text: translationsObject.darkBackgroundDesc,
			fileName: translationsObject.exports.FullLogoWhite,
			downloadButtonColor: DefaultTheme.colors.white,
			logoPng: FullLogoWhite, logoSvg: FullLogoWhiteSVG
		},
		{
			containerBackgroundColor: '#304b9a',
			text: translationsObject.darkerColoredBackgroundDesc,
			fileName: translationsObject.exports.FullLogoYellowWhite,
			downloadButtonColor: DefaultTheme.colors.white,
			logoPng: FullLogoYellowWhite, logoSvg: FullLogoYellowWhiteSVG
		},
	], [translationsObject]);
}

export function useLogosToAvoid(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.logoGuidelines;

	return useMemo(() => [
		{text: translationsObject.outlinedLogoDesc, logo: FullLogoOutlined,},
		{text: translationsObject.gradientLogoDesc, logo: FullLogoGradient,},
		{text: translationsObject.bottomPaddingLogoDesc, logo: FullLogoBottomPadding,},
		{text: translationsObject.disproportionallyScaleLogoDesc, logo: FullLogoDissproportionalScale,},
		{text: translationsObject.shadowLogoDesc, logo: FullLogoShadow,},
		{text: translationsObject.yellowLogoDesc, logo: FullLogoYellow,}
	], [translationsObject]);
}
