import Environment from "../Environment";

export function replaceTexts(text){
	return text.replaceParameters({
		emailAddress: Environment.contact.email,
		dpoEmailAddress: Environment.contact.dpoEmail,
		hrEmailAddress: Environment.contact.hrEmail,
		websiteUrl: Environment.websiteUrl,
	})
}

export default function translationProxy(proxiedObject) {
	if(!proxiedObject) return '';

	return new Proxy(
		proxiedObject,
		{
			get: function(obj, name) {
				if(typeof obj[name] === 'string'){
					return replaceTexts(obj[name])
				}

				if(proxiedObject.hasOwnProperty('length')){
					return obj[name];
				}

				return translationProxy(obj[name]);
			},
		}
	);
}
