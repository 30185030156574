import React from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";

import Typography from "../../../../../../components/Typography";

const LinkSectionsWrapper = styled.div`
  display: flex;
	column-gap: ${props => props.theme.sizes.xxl}px;
	row-gap: ${props => props.theme.sizes.lg}px;
  flex-wrap: wrap;

	@media all and ${({theme: {device}}) => device.sm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
	}
`;

const CategoryContainer = styled.div`
	display: grid;
	grid-row-gap: 30px;
	grid-template-rows: max-content 1fr;
`;

const CategoryTitle = styled(Typography.H3)``;

const CategoryItemsWrapper = styled.div`
  display: grid;
	grid-row-gap: 15px;
	grid-template-rows: repeat(${props => props.numberOfItems}, min-content);
	align-items: start;
`;

const CategoryItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const ItemLink = styled(Link)`
 &, &:hover, &:active, &:visited {
	 text-decoration: none;
 }
`;

const LinksSection = ({sections}) => {
  return (
	  <LinkSectionsWrapper categories={sections.length}>
		  {sections.map(({category, items}, categoryIndex) => (
			  <CategoryContainer key={`c-${categoryIndex}`}>
				  <CategoryTitle>{category}</CategoryTitle>
				  <CategoryItemsWrapper numberOfItems={items.length}>
					  {items.map(({title, to, target, isNew}, index) =>
						  <CategoryItemContainer key={`${categoryIndex}-l-${index}`}>
							  <ItemLink to={to} target={target}>
								  <Typography.ButtonText size={'sm'}>{title}</Typography.ButtonText>
							  </ItemLink>
						  </CategoryItemContainer>
					  )}
				  </CategoryItemsWrapper>
			  </CategoryContainer>
		  ))}
	  </LinkSectionsWrapper>
  );
};

export default LinksSection;
