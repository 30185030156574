import React from 'react';
import styled from "styled-components";

import MenuItem from "./components/MenuItem";

export const MenuSectionContainer = styled.div`
	display: flex;
	flex: 1;
	column-gap: ${props => props.theme.sizes.xl}px;
	align-items: center;
	justify-content: space-between;
	
	@media all and ${({theme: {device}}) => device.sm} {
		display: grid;
	}
`;

export const MenuItemsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(${props => props.numberOfItems}, max-content);
  grid-column-gap: ${props => props.theme.sizes.md}px;
	
	@media all and ${({theme: {device}}) => device.md} {
    grid-column-gap: ${props => props.theme.sizes.sm}px;
  }
`;

const MenuSection = ({
	items,
  buttonComponent = React.Fragment,
}) => {
  return (
    <MenuSectionContainer>
	    <MenuItemsWrapper numberOfItems={items.length}>
		    {items.map((item, index) =>
			    <MenuItem key={index} {...item} />
		    )}
	    </MenuItemsWrapper>
	    {buttonComponent}
    </MenuSectionContainer>
  );
};

export default MenuSection;
