import React from "react";
import {Switch} from "react-router-dom";

import {useIntercom} from "./hooks/useIntercom";
import {useScrollToTopHandler} from "./hooks/useScrollToTopHandler";
import {useGoogleAnalytics} from "../Tracking/GoogleAnalytics/useGoogleAnalytics";

import ContextAwareRoute from "./Routes/ContextAwareRoute";
import getApplicationRoutes from "./ApplicationRoutes";

const ApplicationRouter = () => {
	useIntercom();
	useGoogleAnalytics();
	useScrollToTopHandler();

	return (
		<Switch>
			{getApplicationRoutes().map((route, index) =>
				<ContextAwareRoute {...route} key={index} />
			)}
		</Switch>
	);
};

export default ApplicationRouter;
