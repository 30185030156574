import getRegionEnvironmentVariables from './builders/RegionEnvironmentVariables';
import getBuildTypeEnvironmentVariables from './builders/BuildTypeEnvironmentVariables';

import {
  APP_REGION_AE,
  APP_REGION_COM,
  APP_REGION_CY, DEVELOPMENT,
  DOMAIN_TO_REGION_MAP,
  HOSTNAME_TO_ENV_TYPE, PRODUCTION, STAGING
} from "./EnvironmentConstants";

export function getEnvironment() {
  const envType = getEnvType();
  const appRegion = getAppRegion();

  const regionEnvironmentVariables = getRegionEnvironmentVariables(envType, appRegion);

  return Object.assign(
    {},
    getBuildTypeEnvironmentVariables(envType),
    regionEnvironmentVariables,
  );
}

function getAppRegion(){
  switch (true){
    case hostnameIncludesItems(DOMAIN_TO_REGION_MAP[APP_REGION_CY]):
      return APP_REGION_CY;
    case hostnameIncludesItems(DOMAIN_TO_REGION_MAP[APP_REGION_AE]):
      return APP_REGION_AE;
    case hostnameIncludesItems(DOMAIN_TO_REGION_MAP[APP_REGION_COM]):
    default:
      return APP_REGION_COM;
  }
}

function getEnvType(){
  switch (true){
    case hostnameIncludesItems(HOSTNAME_TO_ENV_TYPE[DEVELOPMENT]):
      return DEVELOPMENT;
    case hostnameIncludesItems(HOSTNAME_TO_ENV_TYPE[STAGING]):
      return STAGING;
    case hostnameIncludesItems(HOSTNAME_TO_ENV_TYPE[PRODUCTION]):
    default:
      return PRODUCTION;
  }
}

function hostnameIncludesItems(items) {
  const hostname = window.location.host;
  return items.filter(item => hostname.includes(item)).length > 0;
}
