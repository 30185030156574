import styled from 'styled-components';
import {rowSpacingSize} from "../styles";

export const TableContainer = styled.div`
  display: grid;
  grid-row-gap: ${rowSpacingSize / 2}px;
`;

export const Table = styled.table`
	border-collapse: collapse;
`;

export const Row = styled.tr`
`;

export const Cell = styled.td`
  border: 1px solid black;
	padding: 10px;
`;

export const HeaderCell = styled(Cell)`
  background-color: #efefef;
	font-weight: 600;
`;


