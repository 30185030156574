import styled from "styled-components";

export const ContentSizeLimiter = styled.div`
  ${props => props.fullHeight === true && `
    display: flex;
    height: 100%;
  `}
  
  margin: auto;
  max-width: var(--max-content-size);
  padding: 0 ${props => props.theme.sizes['3xl']}px;

	@media all and ${({theme: {device}}) => device.sm} {
    width: 100%;
    box-sizing: border-box;
    padding: 0 ${props => props.theme.sizes.md}px;
  }
`;

export default ContentSizeLimiter;
