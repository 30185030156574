import React from 'react';

import whiteFullLogo from './assets/logo-full-white.png'
import primaryFullLogo from './assets/logo-full-primary.png'

import premiumWhite from './assets/logo-premium-white.png';
import premiumDark from './assets/logo-premium-dark.png';

const CompanyLogo = ({variant, colorVariant, ...props}) => {
	const variants = {
		full: {
			white: whiteFullLogo,
			primary: primaryFullLogo,
		},
		premium: {
			white: premiumWhite,
			dark: premiumDark,
		}
	};

  return (
    <img alt={''} src={variants[variant][colorVariant]} {...props} />
  );
};

CompanyLogo.defaultProps = {
	variant: 'full',
	colorVariant: 'white',
}

export default CompanyLogo;
