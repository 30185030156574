export const CONSENT_GIVEN_COOKIE_NAME = 'papel_consent_given';
export const PREFERENCES_COOKIE_NAME = 'papel_preferences_consent';
export const STATISTICS_COOKIE_NAME = 'papel_statistics_consent';
export const PERFORMANCE_COOKIE_NAME = 'papel_performance_consent';
export const MARKETING_COOKIE_NAME = 'papel_marketing_consent';
export const EXTERNAL_COOKIE_NAME = 'papel_external_consent';

export const OPTIONAL_COOKIES = [
	PREFERENCES_COOKIE_NAME,
	STATISTICS_COOKIE_NAME,
	PERFORMANCE_COOKIE_NAME,
	MARKETING_COOKIE_NAME,
	EXTERNAL_COOKIE_NAME
];

export const ALL_COOKIES = [
	CONSENT_GIVEN_COOKIE_NAME,
	...OPTIONAL_COOKIES
];
