import React, {useContext} from 'react';
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import sectionIconImage from './assets/section-icon.png'
import useItems from "./useItems";

import {SectionTitleContainer, SectionIcon, SectionTitleText} from "../../styles";
import {
  SectionContainer, SectionDescriptionText,
  SectionItemContainer, SectionItemDescription,
  SectionItemIcon,
  SectionItemsWrapper,
  SectionItemTitle,
} from "./styles";

const UpgradeSection = () => {
  const {translations} = useContext(LocalisationContext);
  const translationsObject = translations.staticScreens.technologyScreen.upgradeSection;
  const items = useItems();

  return (
    <SectionContainer>
      <SectionTitleContainer>
        <SectionIcon icon={sectionIconImage} />
        <SectionTitleText>{translationsObject.title}</SectionTitleText>
        <SectionDescriptionText>{translationsObject.description}</SectionDescriptionText>
      </SectionTitleContainer>
      <SectionItemsWrapper>
        {items.map((item, index) => (
          <SectionItemContainer key={index}>
            <SectionItemIcon icon={item.icon} />
            <SectionItemTitle>{item.title}</SectionItemTitle>
            <SectionItemDescription>{item.description}</SectionItemDescription>
          </SectionItemContainer>
        ))}
      </SectionItemsWrapper>
    </SectionContainer>
  );
};

export default UpgradeSection;
