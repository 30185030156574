import React, {useContext} from 'react';
import DefaultTheme from "../../../../library/Theme/DefaultTheme";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import {BoxContainer, BoxDescription, ImageContainer, SectionTitle} from "../../styles";
import {AvoidLogosContainer} from "./styles";

const LogosToAvoid = ({logos}) => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.logoGuidelines;

  return (
    <React.Fragment>
	    <SectionTitle>
		    {translationsObject.avoid}
	    </SectionTitle>
	    <AvoidLogosContainer>
		    {(logos || []).map(({text, logo}, index) => (
			    <BoxContainer key={index}>
				    <ImageContainer backgroundColor={DefaultTheme.colors.white} imageUrl={logo}>
				    </ImageContainer>
				    <BoxDescription>{text}</BoxDescription>
			    </BoxContainer>
		    ))}
	    </AvoidLogosContainer>
    </React.Fragment>
  );
};

export default LogosToAvoid;
