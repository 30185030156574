import React, {createContext, useEffect, useState} from "react";
import intl from "react-intl-universal";
import '../../Translate/localization-polyfill';

import Environment from "../../Environment";
import {TRANSLATIONS} from "../../Translate/LocalizationConstants";
import determineInitialLocale from "../../Translate/helpers";

const initialState = {
	language: Environment.defaultLanguage,
	translations: TRANSLATIONS[Environment.defaultLanguage],
}

export const LocalisationContext = createContext(initialState);
export const LocalisationProvider = ({children}) => {
	const [language, setLanguage] = useState(initialState.language);
	const [translations, setTranslations] = useState(initialState.translations);

	useEffect(() => {
		let currentLocale = determineInitialLocale();
		intl.init({
			currentLocale: currentLocale,
			locales: TRANSLATIONS
		});

		setLanguage(currentLocale);
	}, []);

	useEffect(() => {
		setTranslations(TRANSLATIONS[language]);
	}, [language]);

	//TODO: Fix translations proxy
	// const translationsComputed = useMemo(
	// 	() => translationProxy(translations),
	// 	[translations]
	// )

	return (
		<LocalisationContext.Provider
			value={{
				translations: translations,
				language,
			}}>
			{children}
		</LocalisationContext.Provider>
	);
}
