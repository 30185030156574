import React, {useContext, useMemo} from 'react';
import {ComponentContainer} from "./styles";

import {LocalisationContext} from "../../../../../library/Providers/SharedProviders/LocalisationProvider";
import LegalScreenDocumentArticlesContent
	from "../../../../../static-screens/LegalScreens/components/LegalScreenDocumentArticlesContent";
import {COOKIE_POLICY_ROUTE, PRIVACY_POLICY_ROUTE} from "../../../../../library/Navigation/NavigationConstants";

const AboutTabContent = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.sections.cookieBanner.tabs.about.content;

	const articlesWithParametersTranslated = useMemo(() => {
		const articles = translationsObject.document.articles || [];

		return articles.map(article => {
			const extraContent = article.extraContent;

			return {
				extraContent: extraContent.map(content => {
					const paragraphs = content.paragraphs;
					return {
						paragraphs: paragraphs.map(paragraph => paragraph.replaceParameters({
							privacyPolicyLink: PRIVACY_POLICY_ROUTE,
							cookiePolicyLink: COOKIE_POLICY_ROUTE,
						}))
					}
				})
			}
		});
	}, [translationsObject]);

  return (
		<ComponentContainer>
			<LegalScreenDocumentArticlesContent articles={articlesWithParametersTranslated} />
		</ComponentContainer>
  );
};

export default AboutTabContent;
