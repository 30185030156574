import React, {useContext} from 'react';
import styled from "styled-components";

import Typography from "../../../../../../components/Typography";
import Surface from "../../../../../../components/Atomic/Surface";
import CompanyLogo from "../../../../../../components/Atomic/Branding/CompanyLogo";
import ContentSizeLimiter from "../../../../../../components/UI/Containers/ContentSizeLimiter";

import {LocalisationContext} from "../../../../../Providers/SharedProviders/LocalisationProvider";

import useLinks from "./../useLinks";
import LinksSection from "../components/LinksSection";

const ContentContainer = styled.div`
  display: grid;
	grid-row-gap: ${props => props.theme.sizes.xl}px;
	padding: ${props => props.theme.sizes.xxl}px 0;
	
	@media all and ${({theme: {device}}) => device.sm} {
    padding: ${props => props.theme.sizes.xl}px 0;
  }
`;

const LogoAndLinksWrapper = styled.div`
  display: flex;
	justify-content: space-between;
	column-gap: ${props => props.theme.sizes.lg}px;
	
	img {
		max-width: 139px;
	}

	@media all and ${({theme: {device}}) => device.sm} {
		flex-direction: column;
		row-gap: ${props => props.theme.sizes.lg}px;
	}
`;

const DefaultFooter = () => {
	const {translations} = useContext(LocalisationContext);
	const linkSections = useLinks();

  return (
    <Surface.DarkGradient>
	    <ContentSizeLimiter>
				<ContentContainer>
					<LogoAndLinksWrapper>
						<div><CompanyLogo /></div>
						<LinksSection sections={linkSections} />
					</LogoAndLinksWrapper>

					<Typography.Copy
						size={'sm'}
						dangerouslySetInnerHTML={{__html: translations.sections.staticPageFooter.disclaimerText}}
					/>
				</ContentContainer>
	    </ContentSizeLimiter>
    </Surface.DarkGradient>
  );
};

export default DefaultFooter;
