import React from 'react';
import ContentSizeLimiter from "../../components/UI/Containers/ContentSizeLimiter";

import HeroSection from "./components/HeroSection";
import PartnersSection from "./components/PartnersSection";
import UpgradeSection from "./components/UpgradeSection";
import {SectionsWrapper} from "./styles";

const TechnologyScreen = () => {
  return (
    <React.Fragment>
      <HeroSection />

      <SectionsWrapper>
        <UpgradeSection />
        <ContentSizeLimiter>
          <PartnersSection />
        </ContentSizeLimiter>
      </SectionsWrapper>
    </React.Fragment>
    );
};

export default TechnologyScreen;
