import styled from "styled-components";
import {Link} from "react-router-dom";

export const DefaultButtonSurface = styled.div``;

export const ButtonLink = styled(Link)`
  position: relative;
  display: flex;
	align-items: center;
  flex-direction: ${props => props.reversed === true ? 'row-reverse' : 'row'};
	flex-wrap: nowrap;
	column-gap: ${props => props.theme.sizes.xxs}px;
	
	text-decoration: none;

	padding: 12px 15px;
  letter-spacing: 1px;
  border-radius: ${props => props.rounded === true ? `${props.theme.sizes.xxs}px` : '0'};

  border-width: 1px;
  border-style: solid;

  --button-background-color: var(--base-button-background-color);
  --button-border-color: var(--base-button-background-color);
  --text-color: var(--base-button-text-color);
  
  &[data-type='outlined'] {
    --button-background-color: transparent;
    --text-color: var(--base-button-background-color);
  }

  &[data-type='text'] {
    --button-background-color: transparent;
    --button-border-color: transparent;
    --text-color: var(--base-button-background-color);
	  padding-left: 0;
	  padding-right: 0;
  }

  background-color: var(--button-background-color);
  border-color: var(--button-border-color);
	
	--text-transform: ${props => props.textTransform};
  --button-text-color: var(--text-color);
  --copy-text-color: var(--text-color);
		
  &:hover:not([data-type="text"]) {
    box-shadow: 0 5px 10px rgba(112, 112, 112, 0.16);
    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      content: " ";
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: ${props => props.theme.sizes.xxs}px;
    }
  }
`;
