import React from 'react';

import ParagraphsListRenderer from "./ParagraphsListRenderer";
import ListItemsRenderer from "./ListItemsRenderer";
import TableRenderer from "./TableRenderer";

const DynamicContentRenderer = ({content, customRenderers = {}}) => {
	const contentType = content.type || 'paragraph';

	const ItemRendererMap = {
		'list': ListItemsRenderer,
		'paragraph': ParagraphsListRenderer,
		'table': TableRenderer,
		...customRenderers,
	}

	const RenderedContent = ItemRendererMap[contentType];

	return (<RenderedContent {...{content}} />)
};

export default DynamicContentRenderer;
