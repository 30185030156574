import React from "react";
import {downloadFile} from "../../../../library/Helpers/FileHelper";
import {ReactComponent as DownloadIcon} from "../../assets/downloadIcon.svg";

import {
	BoxContainer,
	BoxDescription,
	ImageContainer,
} from "../../styles";

import {
	LogosContainer,
	ButtonsContainer,
	DownloadButtonContainer,
	ButtonsContainerRight,
} from "./styles";

const DownloadButton = ({logo, extension, fileName, color}) => (
	<DownloadButtonContainer onClick={() => {downloadFile(logo, fileName + extension.toLowerCase())}} color={color}>
		<DownloadIcon/>
		{extension.toUpperCase()}
	</DownloadButtonContainer>
);

const OfficialLogos = ({logos}) => {
	return (
		<LogosContainer>
			{(logos || []).map((item, index) => (
				<BoxContainer key={index}>
					<ImageContainer backgroundColor={item.containerBackgroundColor} imageUrl={item.logoPng}>
						<ButtonsContainerRight>
							<ButtonsContainer>
								<DownloadButton logo={item.logoPng} fileName={item.fileName} extension={'.png'} color={item.downloadButtonColor}/>
								<DownloadButton logo={item.logoSvg} fileName={item.fileName} extension={'.svg'} color={item.downloadButtonColor}/>
							</ButtonsContainer>
						</ButtonsContainerRight>
					</ImageContainer>
					<BoxDescription>{item.text}</BoxDescription>
				</BoxContainer>
			))}
		</LogosContainer>
	);
}

export default OfficialLogos;
