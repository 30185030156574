import React, {useState} from 'react';

import CookieBannerContent from './components/CookieBannerContent';
import {useCookieBanner} from './providers/CookieProviders';
import {CONSENT_GIVEN_COOKIE_NAME} from "./CookieConstants";

const CookieBanner = () => {
	const {consents: cookies, onSaveConsents, onAcceptAll} = useCookieBanner();
	const [cookiePreferences, setCookiePreferences] = useState(cookies);

	const onToggleCookiePreferences= (cookieOptionName, e) => {
		const newOptions = {
			...cookiePreferences,
			[cookieOptionName]: e.target.checked,
		};

		setCookiePreferences(newOptions);
	};

	const onSave = () => onSaveConsents(cookiePreferences);

	if(cookies[CONSENT_GIVEN_COOKIE_NAME] === 'true' || cookies[CONSENT_GIVEN_COOKIE_NAME] === true) {
		return null;
	}

	return (
		<CookieBannerContent
			{...{onSave, onAcceptAll, cookiePreferences, onToggleCookiePreferences}}
		/>
	);
};

export default CookieBanner;
