export const HOMEPAGE_ROUTE = '/';
export const SIGNUP_ROUTE = '/signup';
export const NOT_FOUND_ROUTE = '/404';

/**
 * Landing pages
 */
export const CONTACT_US_ROUTE = '/contact';
export const LOGO_GUIDELINES_ROUTE = '/logo-guidelines';
export const ABOUT_US_ROUTE = '/about';
export const SECURITY_ROUTE = '/security';

export const ECOMMERCE_ROUTE = '/e-commerce';
export const TECHNOLOGY_ROUTE = '/technology';

export const CAREER_ROUTE = '/careers';
export const CAREER_DETAIL_ROUTE_BASE = '/careers/job/';
export const CAREER_DETAIL_ROUTE = CAREER_DETAIL_ROUTE_BASE + ':jobId';
export function getCareerJobLink(jobId){
	return CAREER_DETAIL_ROUTE_BASE + jobId;
}

export const GATEWAY_ROUTE = '/corporate/gateway';
export const POS_ROUTE = '/corporate/pos';
export const WALLET_ROUTE = '/personal/wallet';
export const PERSONAL_FAQ_ROUTE = '/personal/faq';

export const CORPORATE_FAQ_ROUTE = '/corporate/faq';
export const CORPORATE_HOMEPAGE_ROUTE = '/corporate/';

export const PAYMENT_GATEWAY_ROUTE = '/payment-gateway';
export const INTERNATIONAL_MERCHANT_ROUTE = '/international-merchant';
export const QR_PAYMENTS_ROUTE = '/qr-payments';
export const SMART_POS_ROUTE = '/smart-pos';
export const ALL_IN_ONE_POS_ROUTE = '/all-in-one-pos';

//Premium
export const PREMIUM_HOMEPAGE_ROUTE = '/premium';
export const PREMIUM_LIFESTYLE_ROUTE = '/premium/lifestyle';
export const PREMIUM_BUSINESS_ROUTE = '/premium/business';
export const PREMIUM_CARD_COLLECTION_ROUTE = '/premium/card-collection';
export const PREMIUM_CARD_DETAILS_ROUTER_ROUTE = '/premium/card/:cardName';
export const PREMIUM_CARD_DETAILS_ROUTE = '/premium/card/';
export const PREMIUM_ORDER_CARD_ROUTE = '/premium/order-card';

export const LANDING_PAGE_ROUTES = [
	CONTACT_US_ROUTE,
	LOGO_GUIDELINES_ROUTE,
	ABOUT_US_ROUTE,
	SECURITY_ROUTE,
	ECOMMERCE_ROUTE,
  CAREER_ROUTE,
	CAREER_DETAIL_ROUTE,
	TECHNOLOGY_ROUTE,
	POS_ROUTE,

	WALLET_ROUTE,
	GATEWAY_ROUTE,
	PERSONAL_FAQ_ROUTE,
	CORPORATE_FAQ_ROUTE,
	CORPORATE_HOMEPAGE_ROUTE,

	SMART_POS_ROUTE,
	QR_PAYMENTS_ROUTE,
	ALL_IN_ONE_POS_ROUTE,
	PAYMENT_GATEWAY_ROUTE,
	INTERNATIONAL_MERCHANT_ROUTE,

	PREMIUM_HOMEPAGE_ROUTE,
	PREMIUM_LIFESTYLE_ROUTE,
	PREMIUM_BUSINESS_ROUTE,
	PREMIUM_ORDER_CARD_ROUTE,
	PREMIUM_CARD_COLLECTION_ROUTE,
	PREMIUM_CARD_DETAILS_ROUTE,
	PREMIUM_CARD_DETAILS_ROUTER_ROUTE,
];

/**
 * Legal Routes
 */
export const TOS_ROUTE = '/terms-and-conditions';
export const COOKIE_POLICY_ROUTE = '/cookie-policy';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const PRIVACY_POLICY_JOB_APPLICANTS_ROUTE = '/job-applicants-policy';

export const LEGAL_SCREEN_ROUTES = [
	TOS_ROUTE,
	COOKIE_POLICY_ROUTE,
	PRIVACY_POLICY_ROUTE,
	PRIVACY_POLICY_JOB_APPLICANTS_ROUTE
];
