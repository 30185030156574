import colors from "./colors";

const size = {
	xs: '600px',
	sm: '960px',
	md: '1280px',
	lg: '1920px',
}

const breakpoints = {
	xs: '0px',
	sm: '600px',
	md: '960px',
	lg: '1280px',
	xl: '1920px'
};

const breakpointFunctions = {
	up: (breakpoint) => `(min-width: calc(${breakpoint} + 0.02px))`,
	down: (breakpoint) => `(max-width: ${breakpoint})`,
};
breakpointFunctions.between =
	(breakpointMin, breakpointMax) => `${breakpointFunctions.up(breakpointMin)} and ${breakpointFunctions.down(breakpointMax)}`;

breakpointFunctions.only = (breakpoint) => {
	const keys = Object.keys(breakpoints);
	const keyIndex = Object.values(breakpoints).indexOf(breakpoint);
	const isLast = keyIndex === keys.length -1;

	return isLast
		? `${breakpointFunctions.up(breakpoint)}`
		: `${breakpointFunctions.up(breakpoint)} and ${breakpointFunctions.down(breakpoints[keys[keyIndex+1]])}`;
}
/**
 * Legacy function which targets screen sizes up to or equal to `breakpoint (xs | sm | md | lg | xl)`
 */
breakpointFunctions.device = (breakpoint) => {
	const keys = Object.keys(breakpoints);
	const keyIndex = keys.indexOf(breakpoint);
	const isLast = keyIndex === keys.length -1;
	return isLast
		? `${breakpointFunctions.down(breakpoints[keys[keyIndex+1]])}`
		: `${breakpointFunctions.up(breakpoint)}`;
}

const DefaultTheme = {
	sizes: {
		'3xl': 100,
		xxl: 60,
		xl: 40,
		lg: 30,
		md: 20,
		sm: 15,
		xs: 10,
		xxs: 5
	},
	colors: {
		primary: "#58A8B1",
		white: "#ffffff",
		black: "#111111",
		gray: {
			700: "#2a2a2a",
			600: "#474747",
			560: "#7E7E7E",
			550: "#7D7D7D",
			500: "#878787",
			400: "#929292",
			300: "#A7A7A7",
			250: "#B5B5B5",
			200: "#8F9BB3",
			180: "#C5C5C5",
			160: "#C8C8C8",
			150: "#D9DCE6",
			100: "#CCD0D3",
			80: "#F8F8FC",
			60: "#F6F6F6",
			50: "#f3f4f6"
		},
		blue: {
			50: "#e6effe",
			80: "#D7DEF5",
			100: "#159AD5",
			200: "#3c82ff",
			250: "#869CE2",
			300: "#0570F0",
			400: "#0469d4",
			500: "#4169E9",
			600: "#365ACE",
			650: "#3551A6",
			700: "#243B7F",
			800: "#213676"
		},
		...colors,
	},
	fonts: {
		"primary": "Manrope"
	},
	breakpointFunctions: breakpointFunctions,
	newBreakpoints: breakpoints,
	breakpoints: {
		xs: size.xs,
		sm: size.sm,
		md: size.md,
		lg: size.lg,
	},
	device: {
		xs: `(max-width: ${size.xs})`,
		sm: `(max-width: ${size.sm})`,
		md: `(max-width: ${size.md})`,
		lg: `(max-width: ${size.lg})`,
	}
}

export default DefaultTheme;
