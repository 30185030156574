import React, {useContext} from 'react';
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import useItems from "./useItems";
import {SectionContainer, PartnerItemsWrapper, PartnerContainer, PartnerDescription, PartnerLogoContainer} from "./styles";
import {SectionIcon, SectionTitleContainer, SectionTitleText} from "../../styles";
import sectionIconImage from './assets/section-icon.png'

const PartnersSection = () => {
  const {translations} = useContext(LocalisationContext);
  const translationsObject = translations.staticScreens.technologyScreen.partners;
  const partners = useItems();

  return (
    <SectionContainer>
      <SectionTitleContainer>
        <SectionIcon icon={sectionIconImage} />
        <SectionTitleText>{translationsObject.title}</SectionTitleText>
      </SectionTitleContainer>

      <PartnerItemsWrapper>
        {partners.map((partner, index) => (
          <PartnerContainer key={index}>
            <PartnerLogoContainer><img alt={''} src={partner.logo} /></PartnerLogoContainer>
            <PartnerDescription>{partner.description}</PartnerDescription>
          </PartnerContainer>
        ))}
      </PartnerItemsWrapper>
    </SectionContainer>
  );
};

export default PartnersSection;
