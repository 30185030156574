import {useContext, useMemo} from "react";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import paxLogo from './assets/pax-logo.png';
import ecpLogo from './assets/ecp-logo.png';
import visaLogo from './assets/visa-logo.png';
import mastercardLogo from './assets/mastercard-logo.png';

export default function useItems(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.technologyScreen.partners.items;

	return useMemo(() => [
		{...translationsObject.pax, logo: paxLogo},
		{...translationsObject.ecp, logo: ecpLogo},
		{...translationsObject.visa, logo: visaLogo},
		{...translationsObject.mastercard, logo: mastercardLogo},
	], [translationsObject]);
}
