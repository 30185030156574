import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const useIntercom = () => {
	const {listen} = useHistory()

	useEffect(() => {
		window.Intercom("boot", {
			app_id: "wpss2e0x"
		});
	}, []);

	useEffect(() => {
		const listener = listen(() => {
			window.Intercom("update");
		});

		return listener
	}, [listen])
}
