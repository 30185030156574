import styled from "styled-components";

const BaseSurface = styled.div`
	${({roundness}) => roundness !== undefined && `
		border-radius: ${roundness}px;
	`}

  --heading-text-color: ${props => props.theme.colors.black.solid};
  --copy-text-color: ${props => props.theme.colors.gray.dark};
  --button-text-color: ${props => props.theme.colors.gray.solid};
	
  --base-button-background-color: ${props => props.theme.colors.black.solid};
  --base-button-text-color: ${props => props.theme.colors.white.solid};
`;

const PrimarySurface = styled(BaseSurface)`
  background-color: ${props => props.theme.colors.primary};

  --heading-text-color: ${props => props.theme.colors.white.transparent};
  --copy-text-color: ${props => props.theme.colors.white.transparent};
  --button-text-color: ${props => props.theme.colors.white.transparent};
	
  --surface-button-background-color: ${props => props.theme.colors.primary};
  --surface-button-text-color: ${props => props.theme.colors.white.solid};

  color: var(--copy-text-color);
`;

const PremiumSurface = styled(BaseSurface)`
  background-color: ${props => props.theme.colors.premium};
  --button-text-color: ${props => props.theme.colors.gray.solid};

  --base-button-background-color: ${props => props.theme.colors.premium};
  --base-button-text-color: ${props => props.theme.colors.black.solid};

  --surface-button-background-color: ${props => props.theme.colors.premium};
  --surface-button-text-color: ${props => props.theme.colors.black.solid};

  color: var(--copy-text-color);
`;

const CommonLightSurface = styled(BaseSurface)`
  --heading-text-color: ${props => props.theme.colors.black.solid};
  --copy-text-color: ${props => props.theme.colors.gray.dark};
  --button-text-color: ${props => props.theme.colors.gray.solid};

  --base-button-background-color: ${props => props.theme.colors.black.solid};
  --base-button-text-color: ${props => props.theme.colors.white.solid};

  --surface-button-background-color: ${props => props.theme.colors.white.solid};
  --surface-button-text-color: ${props => props.theme.colors.black.solid};
	
	color: var(--copy-text-color);
`;

const LightSurface = styled(CommonLightSurface)`
  background: ${props => props.theme.colors.white.solid};
`;

const LightGradientSurface = styled(CommonLightSurface)`
  background: ${props => props.theme.colors.gradient.light};
`;

const LightGraySurface = styled(CommonLightSurface)`
  background:  ${props => props.theme.colors.gray.light};
`;

const CommonDarkSurface = styled(BaseSurface)`
  --heading-text-color: ${props => props.theme.colors.white.solid};
  --copy-text-color: ${props => props.theme.colors.white.transparent};
  --button-text-color: ${props => props.theme.colors.white.transparent};

  --base-button-background-color: ${props => props.theme.colors.white.solid};
  --base-button-text-color: ${props => props.theme.colors.black.solid};

  --surface-button-background-color: ${props => props.theme.colors.black.solid};
  --surface-button-text-color: ${props => props.theme.colors.white.solid};

  color: var(--copy-text-color);
`;

const DarkSurface = styled(CommonDarkSurface)`
  background-color: ${props => props.theme.colors.black.solid};
`;

const DarkGradientSurface = styled(CommonDarkSurface)`
  background:  ${props => props.theme.colors.gradient.dark};
`;

const Surface = {
	Primary: PrimarySurface,
	Premium: PremiumSurface,
	Light: LightSurface,
	LightGray: LightGraySurface,
	LightGradient: LightGradientSurface,
	Dark: DarkSurface,
	DarkGradient: DarkGradientSurface,
};

export default Surface;
