import styled from "styled-components";
import heroBackgroundImage from './assets/hero-image.png';

export const SectionContainer = styled.div`
	position: relative;
  background-color: #101C3D;
	
	&::after {
		display: block;
		content: '';
    position: absolute;
		width: 50%;
		height: 100%;
		top: 0;
		right: 0;
    background-image: url(${heroBackgroundImage});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 100%;

		@media all and ${({theme: {device}}) => device.lg} {
      background-position: center left;
    }
		
    @media all and ${({theme: {device}}) => device.md} {
	    display: none;
    }
	}
`;

export const ContentItemsWrapper = styled.div`
  display: grid;
	grid-template-columns: 45% 1fr;
	
	@media all and ${({theme: {device}}) => device.md} {
    grid-template-columns: 1fr;
  }
`;

export const ContentContainer = styled.div`
  display: grid;
	grid-row-gap: 20px;
  padding: 80px 0;
	
	@media all and ${({theme: {device}}) => device.md} {
    padding: 120px 0;
  }
`;

export const TitleText = styled.div`
  color: white;
	font-size: 58px;
	line-height: 1.3;
`;

export const ParagraphItemsWrapper = styled.div`
  display: grid;
	grid-row-gap: 20px;
`;

export const ParagraphText = styled.div`
  color: #687497;
	font-size: 20px;
	line-height: 1.8;
`;

export const ImageContainer = styled.div`
  position: relative;
	//background-image: url(${heroBackgroundImage});
	background-size: 80%;
	background-position: center right;
	background-repeat: no-repeat;
	width: 120%;
`;


export const ButtonsWrapper = styled.div`
  display: flex;
	column-gap: 30px;
`;
