import styled from "styled-components";
import {TabPanel} from "@material-ui/lab";

export const ComponentOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.34);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2147483004;
	top: 0;
	left: 0;
	
	display: flex;
`;

export const ComponentContainer = styled.div`
	align-self: flex-end;
	width: 100%;
  background-color: #fafafa;
  color: #60666d;
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
  padding: 30px 0;
`;

export const TabsWrapper = styled.div`
  flex: 1;
`;

export const TabContent = styled(TabPanel)`
  padding: 30px 0 !important;
	
	max-height: 230px;
  overflow-y: auto;
`;

export const ButtonsContainer = styled.div`
  border-top: 1px solid #444;
	padding-top: 30px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
	column-gap: 15px;
	
	@media all and ${({theme: {device}}) => device.xs} {
		flex-direction: column;
		row-gap: 15px;
	}
`;
