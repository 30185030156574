import Environment from "../Environment";
import {DEVELOPMENT, PRODUCTION, STAGING} from "../Environment/EnvironmentConstants";
import {HOMEPAGE_ROUTE, LANDING_PAGE_ROUTES, LEGAL_SCREEN_ROUTES, NOT_FOUND_ROUTE} from "./NavigationConstants";

const DEFAULT_ROUTES_AVAILABLE = [
	HOMEPAGE_ROUTE,
	NOT_FOUND_ROUTE,
	...LANDING_PAGE_ROUTES,
	...LEGAL_SCREEN_ROUTES
];

const DEVELOPMENT_OVERRIDES = DEFAULT_ROUTES_AVAILABLE;
const STAGING_OVERRIDES = DEFAULT_ROUTES_AVAILABLE;
const PRODUCTION_OVERRIDES = DEFAULT_ROUTES_AVAILABLE;

const OVERRIDE_BY_ENVIRONMENT_TYPE = {
	[DEVELOPMENT]: DEVELOPMENT_OVERRIDES,
	[STAGING]: STAGING_OVERRIDES,
	[PRODUCTION]: PRODUCTION_OVERRIDES,
}

export function getRoutesAvailable(){
	const environmentType = Environment.environmentType;
	return OVERRIDE_BY_ENVIRONMENT_TYPE[environmentType];
}
