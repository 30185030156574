import React, {useContext} from 'react';

import DefaultTheme from "../../../../library/Theme/DefaultTheme";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";
import FullLogoBlue from "../../assets/fullLogoBlue.png";

import {
	BoxContainer,
	ImageContainer,
	SectionTitle,
	TextContainer, TextItem,
	TextItems
} from "../../styles";

import {ColorPaletteContainer} from "./styles";

const ColorPalette = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.logoGuidelines;

  return (
    <React.Fragment>
	    <SectionTitle>
		    {translationsObject.colorPalette}
	    </SectionTitle>
	    <ColorPaletteContainer>
		    <BoxContainer>
			    <ImageContainer backgroundColor={DefaultTheme.colors.white} imageUrl={FullLogoBlue} />
		    </BoxContainer>
		    <BoxContainer>
			    <TextContainer backgroundColor={"#304b9a"}>
				    <TextItems>
					    <TextItem>RGB: 48 75 154</TextItem>
					    <TextItem>HEX: #304B9A</TextItem>
					    <TextItem>CMYK: 92 74 2 0</TextItem>
				    </TextItems>
			    </TextContainer>
		    </BoxContainer>
	    </ColorPaletteContainer>
    </React.Fragment>
  );
};

export default ColorPalette;
