const blackColors = {
	solid: '#1D2129',
	transparent: 'rgba(0, 0, 0, 0.08)',
}

const grayColors = {
	dark: 'rgba(0, 0, 0, 0.7)',
	light: 'rgb(248, 249, 251)',
}

const whiteColors = {
	solid: '#fff',
	transparent: '#FFFFFFb3',
}

const gradientColors = {
	light: 'linear-gradient(151.97deg, #D3D5DE 11.83%, #F9F9F9 89.73%)',
	dark: 'linear-gradient(151.97deg, #11162C 11.83%, #32364B 89.73%)',
}

export default {
	primary: '#2B67E8',
	secondary: '#FCEA4B',
	success: '#1BCE78',

	premium: "#CA9C6A",

	white: whiteColors,
	gray: grayColors,
	black: blackColors,

	gradient: gradientColors,
	textOnPrimary: whiteColors.solid,
}
